<template>
  <div>
    <h2 class="content-block">Delivery data</h2>

    <DxDataGrid
      class="dx-card wide-card"
      ref="orderDataGridComponent"
      :data-source="orderGridDataSource"
      :show-borders="true"
      :focused-row-enabled="false"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :allowColumnReordering="false"
      :allowColumnResizing="true"
      :row-alternation-enabled="true"
      :show-column-lines="true"
      :show-row-lines="true"
      @cell-prepared="onCellPrepared"
    >
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />

      <DxToolbar>
        <DxItem location="before">
          <DxSelectBox
            ref="selectBoxOptionsTourComponent"
            label="Current year"
            width="180"
            labelMode="floating"
            placeholder="Wähle ein Jahr"
            valueExpr="year"
            readOnly="true"
            value=""
            :data-source="tourSelectBoxDataSource"
            display-Expr="year"
            @value-changed="selectBoxTourValueChangedEvent"
          />
        </DxItem>
        <DxItem name="addRowButton" location="after"/>
        <DxItem name="saveButton" location="after"/>
        <DxItem name="revertButton" location="after"/>
      </DxToolbar>

      <DxEditing
        mode="batch"
        :allow-adding="true"
        :allow-deleting="true"
        :allow-updating="true"
      />

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-column
        dataField="id"
        caption="id"
        data-type="string"
        :allow-editing="false"
        :visible="false"
      />

      <dx-column
        dataField="id_user"
        caption="id_user"
        data-type="string"
        :allow-editing="false"
        :visible="false"
      />

      <dx-column
        data-field="invoice_no"
        caption="invoice no."
        data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="contract_no"
        caption="contract no."
        data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="item"
        caption="item"
        data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="width_size"
        caption="Width / Size"
        :data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="shipped_quantity"
        caption="Shipped quantity"
        :data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="total_net_weight"
        caption="Total weight"
        :data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="net_weight_conventional_cotton"
        caption="Conventional Cotton"
        data-type="number"
        :allow-editing="true"
      />

      <dx-column
        data-field="net_weight_fairtrade_cotton_ocs"
        caption="OCS Cotton"
        data-type="number"
        :allow-editing="true"
        :allow-updating="true"
      />

      <dx-column
        data-field="net_weight_fairtrade_cotton_gots"
        caption="GOTS Cotton"
        data-type="number"
        :allow-editing="true"
        :allow-updating="true"
      />

      <dx-column
        data-field="net_weight_polyester"
        caption="Polyester"
        data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="net_weight_recycled_polyester"
        caption="Recycled polyester"
        data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="net_weight_tencel"
        caption="Tencel"
        data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="net_weight_cmia"
        caption="Cmia"
        data-type="string"
        :allow-editing="true"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import auth from "../auth";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxToolbar,
  DxItem,
  DxHeaderFilter,
  DxEditing,
} from "devextreme-vue/data-grid";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxDataGrid,
    DxSelectBox,
    DxItem,
    DxColumn,
    DxToolbar,
    DxPager,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
  },

  async mounted() {
    /**
     * Frage die Daten für die UI Komponenten an
     */

    this.orderDataGridInstance.beginCustomLoading();

    try {
      await this.getUserData();
    } catch (error) {
      notify(error, "error", 5000);
      this.orderDataGridInstance.endCustomLoading();
    }

    console.log(new Date().getFullYear());

    this.tourSelectBoxInstance.option("value", new Date().getFullYear())

    this.orderDataGridInstance.endCustomLoading();
    this.orderDataGridInstance.option("loadPanel", { enabled: false });
  },

  data() {
    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "id",
      load: () => {
       return fetch("https://dibella-backend.jg-agency.de/api/v1/data", {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            Authorization: "Basic " + this.userData.authCode,
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              notify(
                "Error: " + response.status + " " + response.statusText,
                "error",
                3000
              );
            }
          })
          
          .catch((error) => {
            throw error;
          });
      },

      update: (key, values) => {
        values.id = key;
        values.year = new Date().getFullYear();

        return fetch("https://dibella-backend.jg-agency.de/api/v1/data", {
          method: "PUT",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + this.userData.authCode,
          },
        }).then((response) => {
          if (response.ok) {
              return response.json();
            } else {
              notify(
                "Error: " + response.status + " " + response.statusText,
                "error",
                3000
              );
            }
        });
      },

      insert: (values) => {

        values.id_user = this.userData.id;
        values.year = new Date().getFullYear();

        return fetch("https://dibella-backend.jg-agency.de/api/v1/data", {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + this.userData.authCode,
          },
        }).then((response) => {
          if (response.ok) {
              return response.json();
            } else {
              notify(
                "Error: " + response.status + " " + response.statusText,
                "error",
                3000
              );
            }
        });
      },

      remove: (key) => {
        return fetch(
          `https://dibella-backend.jg-agency.de/api/v1/data/${encodeURIComponent(key)}`,
          {
            method: "DELETE",
            headers:{
              Authorization: "Basic " + this.userData.authCode,
            }
          }
        ).then((response) => {
          if (response.ok) {
              return response.json();
            } else {
              notify(
                "Error: " + response.status + " " + response.statusText,
                "error",
                3000
              );
            }
        });
      },
    });

    const tourSelectBoxDataSource = new DataSource({
      key: "year",
      load: () => {
        return {year: new Date().getFullYear()};
      },
      byKey: () => {
        return {year: new Date().getFullYear()};
      },
    });


    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,
      tourSelectBoxDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,

      /**
       * Export UserData an die View Komponente
       */
      userData,
    };
  },
  methods: {

    onCellPrepared(e) {
      if (
        e.rowType == "data" &&
        e.column.dataField == "eingabebestellmengeVPE" &&
        e.data.eingabebestellmengeVPE > 0
      ) {
        e.cellElement.classList.add("eingabe");
      }
    },

    /**
     * Bestellen & Warenkorb
     */

    

    disableUIComponents() {
      this.tourSelectBoxInstance.option("disabled", true);
      this.artikelGruppenSelectBoxInstance.option("disabled", true);
    },

    enableUIComponents() {
      this.tourSelectBoxInstance.option("disabled", false);
      this.artikelGruppenSelectBoxInstance.option("disabled", false);
    },

    bestellen() {
      notify("Bestellung erfolgreich verschickt!", "success", 3000);
    },

    /**
     * Display Expression Events
     */

    selectBoxTourDisplayExpression(item) {
      if (item !== null) {
        let dtm = new Date(item.datum);
        let strWochentag;
        switch (dtm.getDay()) {
          case 0:
            strWochentag = "So";
            break;
          case 1:
            strWochentag = "Mo";
            break;
          case 2:
            strWochentag = "Di";
            break;
          case 3:
            strWochentag = "Mi";
            break;
          case 4:
            strWochentag = "Do";
            break;
          case 5:
            strWochentag = "Fr";
            break;
          case 6:
            strWochentag = "Sa";
            break;
        }
        return (
          strWochentag +
          " " +
          item.datum.substring(8, 10) +
          "." +
          item.datum.substring(5, 7) +
          "." +
          item.datum.substring(0, 4)
        );
      }
    },

    /**
     * Get User Data
     */

    async getUserData() {
      await auth.getUser().then((e) => {
        this.userData = e.data;
      });
    },

    /**
     * Filter Grid Functions
     * */




    /**
     * Cell Validator
     */

    validateCellEingabeBestellMengeVPE(options) {
      if (options.data.maxMengeVPE < options.value) {
        return false;
      } else {
        return true;
      }
    },

    /**
     * Zell Funktionen der Grid Spalten
     */

    kzVerfuegbarCellFunction(rowData) {
      if (rowData.kzVerfuegbar === 1) {
        return "";
      } else {
        return "Nicht verfügbar";
      }
    },
    mengeVerfuegbarCellFunction(rowData) {
      if (rowData.mengeVerfuegbar < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.mengeVerfuegbar &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.mengeVerfuegbar + " " + rowData.einheit;
      }
    },
    mengeVerfuegbarKW1CellFunction(rowData) {
      if (rowData.mengeVerfuegbarKW1 < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.mengeVerfuegbarKW1 &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.mengeVerfuegbarKW1 + " " + rowData.einheit;
      }
    },
    mengeVerfuegbarKW2CellFunction(rowData) {
      if (rowData.mengeVerfuegbarKW2 < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.mengeVerfuegbarKW2 &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.mengeVerfuegbarKW2 + " " + rowData.einheit;
      }
    },
    mengeVPECellFunction(rowData) {
      return rowData.mengeVPE + " " + rowData.einheit;
    },
    eingabebestellmengeCellFunction(rowData) {
      if (rowData.eingabebestellmengeVPE > 0 && rowData.mengeVPE > 0) {
        return (
          rowData.eingabebestellmengeVPE * rowData.mengeVPE +
          " " +
          rowData.einheit
        );
      } else if (
        typeof rowData.eingabebestellmengeVPE === "undefined" ||
        rowData.eingabebestellmengeVPE === 0
      ) {
        return "0" + " " + rowData.einheit;
      } else {
        return "0 " + rowData.einheit;
      }
    },
    preisCellFunction(rowData) {
      let einzelpreis = parseFloat(rowData.preis);
      return String(einzelpreis.toFixed(2)) + "€";
    },
  },
  computed: {
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
    tourSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsTourComponent.instance;
    },
  },
};
</script>

<style>
.eingabe {
  background-color: #dcecca !important;
}


.dx-adaptive-item-text{
  background-color: #EAEAEA;
}
</style>
