<template>
  <div>
    <div class="content-block">
      <h2>Finished Goods Consumption</h2>
    </div>

    <DxDataGrid
      class="dx-card wide-card"
      ref="orderDataGridComponent"
      :data-source="orderGridDataSource"
      :show-borders="true"
      :focused-row-enabled="false"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :allowColumnReordering="false"
      :allowColumnResizing="true"
      :row-alternation-enabled="true"
      :show-column-lines="true"
      :show-row-lines="true"
    >
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />

      <DxToolbar>
        <DxItem location="before">
          <DxSelectBox
            ref="selectBoxOptionsTourComponent"
            label="Jahr"
            width="180"
            labelMode="floating"
            placeholder="Wähle ein Jahr"
            valueExpr="year"
            value=""
            :data-source="tourSelectBoxDataSource"
            display-Expr="year"
            @value-changed="selectBoxTourValueChangedEvent"
          />
        </DxItem>
      </DxToolbar>

      <DxEditing
        mode="batch"
        :allow-adding="false"
        :allow-deleting="false"
        :allow-updating="false"
      />

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-column
        data-field="year"
        caption="year"
        data-type="number"
        :allow-editing="false"
        :visible="false"
      />

      <dx-column
        data-field="name"
        caption="Supplier"
        data-type="string"
        :allow-editing="false"
        :visible="true"
      />

      <dx-column
        data-field="cov_cotton"
        caption="Conventional Cotton in kg"
        data-type="number"
        :allow-editing="false"
        :visible="true"
      />

      <dx-column
        data-field="fairtrade_ocs_cotton"
        caption="Fairtrade / OCS cotton in kg"
        data-type="number"
        :allow-editing="true"
      />

      <dx-column
        data-field="fairtrade_gots_cotton"
        caption="Fairtrade / GOTS cotton in kg"
        data-type="number"
        :allow-editing="false"
        :visible="true"
      />

      <dx-column
        data-field="cmia"
        caption="Cotton Made in Africa in kg"
        data-type="number"
        :allow-editing="false"
        :visible="true"
      />

      <dx-column
        data-field="polyester"
        caption="Polyester in kg"
        :data-type="number"
        :allow-editing="false"
      />

      <dx-column
        data-field="recycled_polyester"
        caption="Recycled Polyester in kg"
        data-type="number"
        :allow-editing="false"
      />

      <dx-column
        data-field="tencel"
        caption="Tencel in kg"
        data-type="number"
        :allow-editing="false"
      />

      <DxSummary>
        <DxTotalItem column="cov_cotton" summary-type="sum" />
        <DxTotalItem column="fairtrade_ocs_cotton" summary-type="sum" />
        <DxTotalItem column="fairtrade_gots_cotton" summary-type="sum" />
        <DxTotalItem column="cmia" summary-type="sum" />
        <DxTotalItem column="polyester" summary-type="sum" />
        <DxTotalItem column="recycled_polyester" summary-type="sum" />
        <DxTotalItem column="tencel" summary-type="sum" />
      </DxSummary>
    </DxDataGrid>

    <n-page-header
      style="padding: 30px; padding-left: 35px; padding-bottom: 0px"
      title="GESAMTMENGEN ALS GRAPH"
    >
    </n-page-header>

    <n-grid
      id="chartGrid"
      style="padding: 30px"
      x-gap="12"
      y-gap="12"
      :cols="2"
    >
      <n-gi>
        <DxChart
          id="chart"
          :data-source="barChartTotalConsumption"
          palette="Soft"
        >
          <DxCommonSeriesSettings
            :ignore-empty-points="true"
            argument-field="arg"
            value-field="val"
            type="bar"
          />
          <DxSeriesTemplate name-field="arg" />
          <DxTitle text="GESAMTMENGE TOTAL IN KG" />
        </DxChart>
      </n-gi>
      <n-gi>
        <DxPieChart
          id="pie"
          :data-source="pieChartTotalConsumptionPercentage"
          palette="Bright"
          title="GESAMTMENGEN PROZENTUAL"
          type="doughnut"
        >
          <DxSeries argument-field="name" value-field="percent">
            <DxLabel :visible="true" format="percent">
              <DxConnector :visible="true" :width="1" />
            </DxLabel>
          </DxSeries>
          <DxSize />
        </DxPieChart>
      </n-gi>
    </n-grid>

    <n-page-header
      style="padding: 30px; padding-left: 35px; padding-bottom: 0px"
      title="GESAMTMENGEN IN KG"
    >
    </n-page-header>

    <n-grid
      id="totalGrid"
      style="padding: 30px"
      x-gap="12"
      y-gap="12"
      :cols="4"
    >
      <n-gi>
        <n-card title="Naturfasern">
          {{ totalConsumptionData.totalNaturfasern }} kg |
          {{ totalConsumptionData.totalNaturfasern / 1000 }} t</n-card
        >
      </n-gi>
      <n-gi>
        <n-card title="Baumwolle">
          {{ totalConsumptionData.totalBaumwolle }} kg |
          {{ totalConsumptionData.totalBaumwolle / 1000 }} t</n-card
        >
      </n-gi>
      <n-gi>
        <n-card title="Nachhaltige Baumwolle">
          {{ totalConsumptionData.totalNachhaltigeBaumwolle }} kg |
          {{ totalConsumptionData.totalNachhaltigeBaumwolle / 1000 }} t
        </n-card>
      </n-gi>
      <n-gi>
        <n-card title="Bio Fairtrade">
          {{ totalConsumptionData.totalBioFairtrade }} kg |
          {{ totalConsumptionData.totalBioFairtrade / 1000 }} t</n-card
        >
      </n-gi>
      <n-gi>
        <n-card title="Polyester">
          {{ totalConsumptionData.totalPolyester }} kg |
          {{ totalConsumptionData.totalPolyester / 1000 }} t</n-card
        >
      </n-gi>
      <n-gi>
        <n-card title="Tencel">
          {{ totalConsumptionData.totalTencel }} kg |
          {{ totalConsumptionData.totalTencel / 1000 }} t</n-card
        >
      </n-gi>
      <n-gi>
        <n-card title="Fairtrade">
          {{ totalConsumptionData.totalFairtrade }} kg |
          {{ totalConsumptionData.totalFairtrade / 1000 }} t</n-card
        >
      </n-gi>
      <n-gi>
        <n-card title="Cmia">
          {{ totalConsumptionData.totalCmia }} kg |
          {{ totalConsumptionData.totalCmia / 1000 }} t</n-card
        >
      </n-gi>
    </n-grid>

    <n-page-header
      style="padding: 30px; padding-left: 35px; padding-bottom: 0px"
      title="GESAMTMENGEN PROZENTUAL"
    >
    </n-page-header>

    <n-grid
      id="totalGrid"
      style="padding: 30px"
      x-gap="12"
      y-gap="12"
      :cols="3"
    >
      <n-gi>
        <n-card title="Anteil nachhaltiger Baumwolle an Gesamtmenge ">
          {{ Math.round(this.totalConsumptionDataPercentage.nachhaltigeBaumwolle).toFixed(2) }} % </n-card
        >
      </n-gi>
      <n-gi>
        <n-card title="Anteil Fairtrade an Gesamtmenge Baumwolle">
          {{ Math.round(this.totalConsumptionDataPercentage.fairtradeBaumwolle).toFixed(2) }} % </n-card>
      </n-gi>
      <n-gi>
        <n-card title="Anteil GOTS an Gesamtmenge Baumwolle">
          {{ Math.round(this.totalConsumptionDataPercentage.GOTS).toFixed(2) }} % </n-card>
        
      </n-gi>
      <n-gi>
        <n-card title="Anteil OCS an Gesamtmenge Baumwolle">
          {{ Math.round(this.totalConsumptionDataPercentage.OCS).toFixed(2) }} % </n-card>
      </n-gi>
      <n-gi>
        <n-card title="Anteil CmiA and Gesamtmenge Baumwolle">
          {{ Math.round(this.totalConsumptionDataPercentage.Cmia).toFixed(2) }} % </n-card>
      </n-gi>
      <n-gi>
        <n-card title="Anteil Recycled Polyester an Gesamtmenge Polyester">
          {{ Math.round(this.totalConsumptionDataPercentage.recycled_polyester).toFixed(2) }} % </n-card>
      </n-gi>
    </n-grid>
  </div>
</template>

<script>
import DataSource from "devextreme/data/data_source";
import { DxSelectBox } from "devextreme-vue/select-box";
import notify from "devextreme/ui/notify";
import Helper from "../utils/summaryDelivery";
import { NCard, NGrid, NGi, NPageHeader } from "naive-ui";
import auth from "../auth";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxTotalItem,
  DxSummary,
  DxPaging,
  DxItem,
  DxFilterRow,
  DxHeaderFilter,
  DxToolbar,
  DxEditing,
} from "devextreme-vue/data-grid";

import DxChart, {
  DxSeriesTemplate,
  DxTitle,
  DxCommonSeriesSettings,
  DxLabel,
  DxSeries,
} from "devextreme-vue/chart";

import DxPieChart, { DxSize, DxConnector } from "devextreme-vue/pie-chart";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";
const selectBoxOptionsTourComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";
const selectBoxStatusComponent = "";
const buttonWarenkorb = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxDataGrid,
    DxTitle,
    DxColumn,
    DxToolbar,
    DxSelectBox,
    DxItem,
    DxSummary,
    DxPager,
    DxTotalItem,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
    NCard,
    NPageHeader,
    NGrid,
    NGi,
    DxChart,
    DxLabel,
    DxSeries,
    DxPieChart,
    DxSize,
    DxConnector,
    DxSeriesTemplate,
    DxCommonSeriesSettings,
  },

  async mounted() {
    await this.initMethod();
    this.calculateTotalConsumptionData();
  },

  data() {
    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "id",
      load: () => {
        return Helper.dibellaSummaryAnzeigeArray;
      },
    });

    const tourSelectBoxDataSource = new DataSource({
      key: "year",
      load: () => {
        return Helper.dibellaSummaryYears;
      },
      byKey: () => {
        return Helper.dibellaSummaryYears;
      },
    });

    /**
     * User Data Konstante
     */

    const userData = {};
    const totalConsumptionData = {};
    const totalConsumptionDataPercentage = {};
    const pieChartTotalConsumptionPercentage = {};
    const barChartTotalConsumption = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,
      tourSelectBoxDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,
      selectBoxOptionsTourComponent,
      selectBoxOptionsArtikelGruppenComponent,
      selectBoxStatusComponent,
      buttonWarenkorb,

      /**
       * Export UserData an die View Komponente
       */
      userData,
      totalConsumptionData,
      barChartTotalConsumption,

      totalConsumptionDataPercentage,
      pieChartTotalConsumptionPercentage,
    };
  },
  methods: {
    /**
     * Cell Prepared Styles
     */

    exampleDisplayExpression() {},

    calculateTotalConsumptionData() {
      // --------- Total -------------

      this.totalConsumptionData.totalNaturfasern = 0;
      this.totalConsumptionData.totalBaumwolle = 0;
      this.totalConsumptionData.totalNachhaltigeBaumwolle = 0;
      this.totalConsumptionData.totalBioFairtrade = 0;
      this.totalConsumptionData.totalPolyester = 0;
      this.totalConsumptionData.totalTencel = 0;
      this.totalConsumptionData.totalFairtrade = 0;
      this.totalConsumptionData.totalCmia = 0;

      this.totalConsumptionData.totalOnlyNormalPolyester = 0;
      this.totalConsumptionData.totalGOTS = 0;
      this.totalConsumptionData.totalOCS = 0;
      this.totalConsumptionData.totalRecycled_Polyester = 0;

      Helper.dibellaSummaryAnzeigeArray.forEach((element) => {

        this.totalConsumptionData.totalNaturfasern +=
          parseInt(element.cov_cotton) +
          parseInt(element.fairtrade_ocs_cotton) +
          parseInt(element.fairtrade_gots_cotton) +
          parseInt(element.cmia);
        this.totalConsumptionData.totalBaumwolle =
          this.totalConsumptionData.totalNaturfasern;
        this.totalConsumptionData.totalNachhaltigeBaumwolle +=
          parseInt(element.fairtrade_ocs_cotton) +
          parseInt(element.fairtrade_gots_cotton) +
          parseInt(element.cmia);
        this.totalConsumptionData.totalBioFairtrade +=
          parseInt(element.fairtrade_ocs_cotton) +
          parseInt(element.fairtrade_gots_cotton);
        this.totalConsumptionData.totalPolyester +=
          parseInt(element.polyester) + parseInt(element.recycled_polyester);
        this.totalConsumptionData.totalTencel += parseInt(element.tencel);
        this.totalConsumptionData.totalFairtrade +=
          parseInt(element.fairtrade_ocs_cotton) +
          parseInt(element.fairtrade_gots_cotton);
        this.totalConsumptionData.totalCmia += parseInt(element.cmia);

        this.totalConsumptionData.totalOnlyNormalPolyester += parseInt(element.polyester);

        this.totalConsumptionData.totalRecycled_Polyester += parseInt(element.recycled_polyester);
        this.totalConsumptionData.totalGOTS += parseInt(element.fairtrade_gots_cotton);
        this.totalConsumptionData.totalOCS += parseInt(element.fairtrade_ocs_cotton);
      });

      this.barChartTotalConsumption = [
        {
          arg: "Naturfasern",
          val: this.totalConsumptionData.totalNaturfasern,
        },
        {
          arg: "Baumwolle",
          val: this.totalConsumptionData.totalBaumwolle,
        },
        {
          arg: "Nachhaltige Baumwolle",
          val: this.totalConsumptionData.totalNachhaltigeBaumwolle,
        },
        {
          arg: "Bio Fairtrade",
          val: this.totalConsumptionData.totalBioFairtrade,
        },
        {
          arg: "Polyester",
          val: this.totalConsumptionData.totalPolyester,
        },
        {
          arg: "Tencel",
          val: this.totalConsumptionData.totalTencel,
        },
        {
          arg: "Fairtrade",
          val: this.totalConsumptionData.totalFairtrade,
        },
        {
          arg: "Cmia",
          val: this.totalConsumptionData.totalCmia,
        },
      ];

      //--------------- prozentual ----------------

      this.totalConsumptionDataPercentage.nachhaltigeBaumwolle = this.totalConsumptionData.totalNachhaltigeBaumwolle * 100 / this.totalConsumptionData.totalBaumwolle;
      this.totalConsumptionDataPercentage.fairtradeBaumwolle = this.totalConsumptionData.totalFairtrade * 100 / this.totalConsumptionData.totalBaumwolle;
      this.totalConsumptionDataPercentage.GOTS = this.totalConsumptionData.totalGOTS * 100 / this.totalConsumptionData.totalBaumwolle;
      this.totalConsumptionDataPercentage.OCS = this.totalConsumptionData.totalOCS * 100 / this.totalConsumptionData.totalBaumwolle;
      this.totalConsumptionDataPercentage.Cmia = this.totalConsumptionData.totalCmia * 100 / this.totalConsumptionData.totalBaumwolle;

      console.log("rechne " + this.totalConsumptionData.totalRecycled_Polyester * 100 / this.totalConsumptionData.totalOnlyNormalPolyester);


      this.totalConsumptionDataPercentage.recycled_polyester = this.totalConsumptionData.totalRecycled_Polyester * 100 / this.totalConsumptionData.totalOnlyNormalPolyester;

      this.pieChartTotalConsumptionPercentage = [
      {
        name: "Anteil nachhaltiger Baumwolle an Gesamtmenge Baumwolle",
        percent: this.totalConsumptionDataPercentage.nachhaltigeBaumwolle * 10,
      },
      {
        name: "Anteil Fairtrade an Gesamtmenge Baumwolle",
        percent: this.totalConsumptionDataPercentage.fairtradeBaumwolle * 10,
      },
      {
        name: "Anteil GOTS an Gesamtmenge Baumwolle",
        percent: this.totalConsumptionDataPercentage.GOTS * 10,
      },
      {
        name: "Anteil OCS an Gesamtmenge Baumwolle",
        percent: this.totalConsumptionDataPercentage.OCS * 10,
      },
      {
        name: "Anteil CmiA and Gesamtmenge Baumwolle",
        percent: this.totalConsumptionDataPercentage.Cmia * 10,
      },
    ];

    },

    /**
     * Grid Aktualisieren
     */

    async initMethod() {
      /**
       * Frage die Daten für die UI Komponenten an
       */

      this.orderDataGridInstance.option("loadPanel", { enabled: true });
      this.orderDataGridInstance.beginCustomLoading();

      try {
        await this.getUserData();
        await Helper.getData(this.userData.authCode);
        await Helper.getYearSelectBoxData(this.userData.authCode);
      } catch (error) {
        notify(error, "error", 5000);
      }

      if(Helper.dibellaSummaryAnzeigeArray.length <= 0 || Helper.dibellaSummaryYears.length <= 0){
        notify("No Data", "error", 5000);
        this.orderDataGridInstance.endCustomLoading();  
      }

      this.tourSelectBoxInstance.option("value", Helper.dibellaSummaryYears[0]);
      this.filterOrderGridNachTour(Helper.dibellaSummaryYears[0].year);

      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();
      this.orderDataGridInstance.option("loadPanel", { enabled: false });
    },

    /**
     * Value Change Events
     */

    selectBoxArtikelGruppenValueChangedEvent(item) {
      this.filterOrderGridNachArtikelGruppe(item.value);
    },

    selectBoxTourValueChangedEvent(item) {
      this.filterOrderGridNachTour(item.value);
    },

    /**
     * Get User Data
     */

    async getUserData() {
      await auth.getUser().then((e) => {
        this.userData = e.data;
      });
    },

    /**
     * Filter Grid Functions
     * */

    setOfferStatus() {
      for (let index = 0; index < Helper.gridAlleArtikelDaten.length; index++) {
        if (Helper.gridAlleArtikelDaten[index].bestellmenge > 0) {
          this.statusSelectBoxInstance.option("value", "Angeboten");
          return;
        }
      }
      this.statusSelectBoxInstance.option("value", "Neu");
    },

    filterOrderGridNachArtikelGruppe(ChosenArtikelGruppe) {
      var objGridAnzeigeNeu = [];
      Helper.gridAnzeigeTour.forEach(function (item) {
        if (
          item.artikelgruppe === ChosenArtikelGruppe ||
          ChosenArtikelGruppe === "Alle Artikel"
        ) {
          objGridAnzeigeNeu.push(item);
        }
      });
      Helper.gridAnzeige = objGridAnzeigeNeu;
      this.orderDataGridInstance.refresh();
    },

    filterOrderGridNachTour(item) {
      var tempArray = [];

      Helper.dibellaSummaryArray.forEach(function (element) {
        if (item === element.year) {
          tempArray.push(element);
        }
      });

      Helper.dibellaSummaryAnzeigeArray = tempArray;
      this.orderDataGridInstance.refresh();

      this.calculateTotalConsumptionData();
    },

    setzeArtikelGruppenDataSource() {
      var artikelGruppen = ["Alle Artikel"];
      var gridAlleArtikelDatenCopy = Helper.gridAlleArtikelDaten;
      for (var i = 0; i < gridAlleArtikelDatenCopy.length; i++) {
        if (
          !artikelGruppen.includes(gridAlleArtikelDatenCopy[i].artikelgruppe)
        ) {
          artikelGruppen.push(gridAlleArtikelDatenCopy[i].artikelgruppe);
        }
      }
      Helper.selextBoxArtikelGruppenDaten = artikelGruppen;
    },

    /**
     * Cell Validator
     */

    revalidate() {
      console.log("custom callback");
    },

    /**
     * Zell Funktionen der Grid Spalten
     */

    kzVerfuegbarCellFunction(rowData) {
      if (rowData.kzVerfuegbar === 1) {
        return "";
      } else {
        return "Nicht verfügbar";
      }
    },
    mengeVerfuegbarCellFunction(rowData) {
      if (rowData.bestellteMenge < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.bestellteMenge &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.bestellteMenge + " " + rowData.einheit;
      }
    },
    mengeVPECellFunction(rowData) {
      return rowData.mengeVPE + " " + rowData.einheit;
    },
    eingabebestellmengeCellFunction(rowData) {
      if (rowData.bestellmenge > 0 && rowData.mengeVPE > 0) {
        return rowData.bestellmenge * rowData.mengeVPE + " " + rowData.einheit;
      } else if (
        typeof rowData.bestellmenge === "undefined" ||
        rowData.bestellmenge === 0
      ) {
        return "0" + " " + rowData.einheit;
      } else {
        return "0 " + rowData.einheit;
      }
    },
    preisCellFunction(rowData) {
      let einzelpreis = parseInt(rowData.preis);
      return String(einzelpreis.toFixed(2)) + "€";
    },
  },
  computed: {
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
    tourSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsTourComponent.instance;
    },
  },
};
</script>

<style>
.eingabe {
  background-color: #dcecca !important;
}

.totalGrid n-card {
  margin-top: 20px;
}

#chart {
  height: 440px;
}
</style>
