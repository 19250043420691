export default {
  API_URL: "https://dibella-backend.jg-agency.de/api/v1/",
  selectBoxTourDaten: [],
  selextBoxArtikelGruppenDaten: [],
  gridAlleArtikelDaten: [],
  gridAnzeige: [],
  gridAnzeigeTour: [],
  gridAlleArtikelFilter: [],
  gridWarenkorbArtikel: [],
  warenkorbToggleStatus: false,
  anbietenDaten: [],
  originalBestellMenge: [],

  dibellaSummaryArray: [],
  dibellaSummaryAnzeigeArray: [],
  dibellaSummaryYears: [],

  /**
   * Status des aktuellen Bestellungsvorgangs
   * Neu: es wurde noch nichts bestellt
   * In Bearbeitung: Es befinden sich Angebote (Zellen) in der Bearbeitung
   * Angeboten: Click auf anbieten und positiv callback von der API
   */

  getData: async function (authCode) {
    return await fetch("https://dibella-backend.jg-agency.de/api/v1/summaryData", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        Authorization: "Basic " + authCode,
      },
    })
      .then((response) => {
        if(response.ok){
          return response.json();
        } else {
          throw new Error('Code ' + response.status + " " + response.statusText);
        }
      })
      .then((result) => {
        console.log("daten erhalten", result)
        this.dibellaSummaryArray = result;
        this.dibellaSummaryAnzeigeArray = result;
      })
      .catch((error) => {
        throw error;
      });
  },


  getYearSelectBoxData: async function (authCode) {
    return await fetch("https://dibella-backend.jg-agency.de/api/v1/summaryData/getyears", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        Authorization: "Basic " + authCode,
      },
    })
      .then((response) => {
        if(response.ok){
          return response.json();
        } else {
          throw new Error('Code ' + response.status + " " + response.statusText);
        }
      })
      .then((result) => {
        console.log("daten erhalten", result)
        this.dibellaSummaryYears = result;
      })
      .catch((error) => {
        throw error;
      });
  },
};
