export default [
  {
    text: "Home",
    path: "/home",
    icon: "home",
    role: 0,
  },
  {
    text: "Administration",
    icon: "preferences",
    role: 2,
    items: [
      {
        text: "User Configuration",
        path: "/user-configuration",
      },
      {
        text: "Finished Goods Consu...",
        path: "/summary",
        role: 2,
      },
    ]
  },
  {
    text: "Data Management",
    icon: "file",
    role: 0,
    items: [
      {
        text: "Delivery data",
        path: "/delivery-data",
        role: 1,
      },
    ]
  },
  {
    text: "Profile",
    icon: "card",
    role: 0,
    items: [
      {
        text: "Settings",
        path: "/profile",
        role: 0,
      },
      {
        text: "Logout",
        path: "/logout",
        role: 0,
      }
    ]
  }
];
