<template>
  <div>
    <h2 class="content-block">User configuration</h2>

    <DxDataGrid
      class="dx-card wide-card"
      ref="orderDataGridComponent"
      :data-source="orderGridDataSource"
      :show-borders="true"
      :focused-row-enabled="false"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :allowColumnReordering="false"
      :allowColumnResizing="true"
      :row-alternation-enabled="true"
      :show-column-lines="true"
      :show-row-lines="true"
      @cell-prepared="onCellPrepared"
    >
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />

      <DxEditing
        mode="batch"
        :allow-adding="true"
        :allow-deleting="true"
        :allow-updating="true"
      />

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-column
        dataField="id"
        caption="id"
        data-type="string"
        :allow-editing="false"
      />

      <dx-column
        data-field="name"
        caption="name"
        data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="email"
        caption="email"
        data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="role"
        caption="role"
        data-type="string"
        :allow-editing="true"
      />

      <dx-column
        data-field="password"
        caption="Password"
        data-type="string"
        :allow-editing="true"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import auth from "../../auth";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxHeaderFilter,
  DxEditing,
} from "devextreme-vue/data-grid";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";
const selectBoxOptionsTourComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";
const buttonWarenkorb = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
  },

  async mounted() {
    await this.getUserData();
  },

  data() {
    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "id",
      load: () => {
        return fetch("https://dibella-backend.jg-agency.de/api/v1/user", {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            Authorization: "Basic " + this.userData.authCode,
          },
        })
          .then((response) => {
            console.log(response);

            if (response.ok) {
              return response.json();
            } else {
              notify(
                "Error: " + response.status + " " + response.statusText,
                "error",
                3000
              );
            }
          })

          .catch((error) => {
            notify(error, "error", 3000);
          });
      },

      update: (key, values) => {
        values.id = key;
        return fetch("https://dibella-backend.jg-agency.de/api/v1/user", {
          method: "PUT",

          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + this.userData.authCode,
          },
        }).then((response) => {
          console.log(response);

          if (response.ok) {
            return response.json();
          } else {
            notify(
              "Error: " + response.status + " " + response.statusText,
              "error",
              3000
            );
          }
        })
        .catch((error) => {
            notify(error, "error", 3000);
          });
      },

      insert: (values) => {
        return fetch("https://dibella-backend.jg-agency.de/api/v1/user", {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + this.userData.authCode,
          },
        }).then((response) => {
          console.log(response);

          if (response.ok) {
            return response.json();
          } else {
            notify(
              "Error: " + response.status + " " + response.statusText,
              "error",
              3000
            );
          }
        })
        .catch((error) => {
            notify(error, "error", 3000);
          });
      },

      remove: (key) => {
        return fetch(
          `https://dibella-backend.jg-agency.de/api/v1/user/${encodeURIComponent(key)}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic " + this.userData.authCode,
            },
          }
        ).then((response) => {
          console.log(response);

          if (response.ok) {
            return response.json();
          } else {
            notify(
              "Error: " + response.status + " " + response.statusText,
              "error",
              3000
            );
          }
        })
        .catch((error) => {
            notify(error, "error", 3000);
          });
      },
    });

    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,
      selectBoxOptionsTourComponent,
      selectBoxOptionsArtikelGruppenComponent,
      buttonWarenkorb,

      /**
       * Export UserData an die View Komponente
       */
      userData,
    };
  },
  methods: {
    onCellPrepared(e) {
      if (
        e.rowType == "data" &&
        e.column.dataField == "eingabebestellmengeVPE" &&
        e.data.eingabebestellmengeVPE > 0
      ) {
        e.cellElement.classList.add("eingabe");
      }
    },

    /**
     * Bestellen & Warenkorb
     */

    disableUIComponents() {
      this.tourSelectBoxInstance.option("disabled", true);
      this.artikelGruppenSelectBoxInstance.option("disabled", true);
    },

    enableUIComponents() {
      this.tourSelectBoxInstance.option("disabled", false);
      this.artikelGruppenSelectBoxInstance.option("disabled", false);
    },

    bestellen() {
      notify("Bestellung erfolgreich verschickt!", "success", 3000);
    },

    /**
     * Display Expression Events
     */

    selectBoxTourDisplayExpression(item) {
      if (item !== null) {
        let dtm = new Date(item.datum);
        let strWochentag;
        switch (dtm.getDay()) {
          case 0:
            strWochentag = "So";
            break;
          case 1:
            strWochentag = "Mo";
            break;
          case 2:
            strWochentag = "Di";
            break;
          case 3:
            strWochentag = "Mi";
            break;
          case 4:
            strWochentag = "Do";
            break;
          case 5:
            strWochentag = "Fr";
            break;
          case 6:
            strWochentag = "Sa";
            break;
        }
        return (
          strWochentag +
          " " +
          item.datum.substring(8, 10) +
          "." +
          item.datum.substring(5, 7) +
          "." +
          item.datum.substring(0, 4)
        );
      }
    },

    /**
     * Value Change Events
     */

    selectBoxArtikelGruppenValueChangedEvent(item) {
      this.filterOrderGridNachArtikelGruppe(item.value);
    },

    selectBoxTourValueChangedEvent(item) {
      this.filterOrderGridNachTour(item.value);
    },

    /**
     * Get User Data
     */

    async getUserData() {
      await auth.getUser().then((e) => {
        this.userData = e.data;
      });
    },

    /**
     * Filter Grid Functions
     * */


    /**
     * Cell Validator
     */

    validateCellEingabeBestellMengeVPE(options) {
      if (options.data.maxMengeVPE < options.value) {
        return false;
      } else {
        return true;
      }
    },

    /**
     * Zell Funktionen der Grid Spalten
     */

    kzVerfuegbarCellFunction(rowData) {
      if (rowData.kzVerfuegbar === 1) {
        return "";
      } else {
        return "Nicht verfügbar";
      }
    },
    mengeVerfuegbarCellFunction(rowData) {
      if (rowData.mengeVerfuegbar < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.mengeVerfuegbar &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.mengeVerfuegbar + " " + rowData.einheit;
      }
    },
    mengeVerfuegbarKW1CellFunction(rowData) {
      if (rowData.mengeVerfuegbarKW1 < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.mengeVerfuegbarKW1 &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.mengeVerfuegbarKW1 + " " + rowData.einheit;
      }
    },
    mengeVerfuegbarKW2CellFunction(rowData) {
      if (rowData.mengeVerfuegbarKW2 < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.mengeVerfuegbarKW2 &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.mengeVerfuegbarKW2 + " " + rowData.einheit;
      }
    },
    mengeVPECellFunction(rowData) {
      return rowData.mengeVPE + " " + rowData.einheit;
    },
    eingabebestellmengeCellFunction(rowData) {
      if (rowData.eingabebestellmengeVPE > 0 && rowData.mengeVPE > 0) {
        return (
          rowData.eingabebestellmengeVPE * rowData.mengeVPE +
          " " +
          rowData.einheit
        );
      } else if (
        typeof rowData.eingabebestellmengeVPE === "undefined" ||
        rowData.eingabebestellmengeVPE === 0
      ) {
        return "0" + " " + rowData.einheit;
      } else {
        return "0 " + rowData.einheit;
      }
    },
    preisCellFunction(rowData) {
      let einzelpreis = parseFloat(rowData.preis);
      return String(einzelpreis.toFixed(2)) + "€";
    },
  },
  computed: {
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
    tourSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsTourComponent.instance;
    },
    artikelGruppenSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsArtikelGruppenComponent.instance;
    },
    buttonWarenkorbInstance: function () {
      return this.$refs.buttonWarenkorb.instance;
    },
  },
};
</script>

<style>
.eingabe {
  background-color: #dcecca !important;
}
</style>
