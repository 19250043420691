const user = null;

export default {
  _user: user,
  loggedIn() {
    return !!this._user;
  },

  async logIn(email, password) {
    try {
      // Send request
        let response = await fetch("https://dibella-backend.jg-agency.de/api/v1/user/authenticate", {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Basic " + btoa(email + ":" + password)
          }
        });

        let data = await response.json();
        let status = await response.status;

        data.authCode = btoa(email + ":" + password);
        this._user = data;
        console.log(this._user);

        if(status <= 200){
          return {
            isOk: true,
            data: this._user,
          }
        } else {
          return {
            isOk: false,
            data: this._user,
            message: "Fehler: " + data.message,
          }
        }
    } catch (e) {
      return {
        isOk: false,
        message: "Fehler: " + e,
      };
    }
  },

  async logOut() {
    this._user = null;
  },

  async getUser() {
    try {
      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to reset password",
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to change password",
      };
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to create account",
      };
    }
  },
};
