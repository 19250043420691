<template>
  <div>
    <h2 class="content-block">Home</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div style="text-align: center">
          <h3 style="margin-top: 0px">Neuigkeiten</h3>
        </div>

        <n-grid x-gap="12" :cols="3">
          <n-gi style="background:black">
            <n-card title="Card"> Card Content </n-card>
          </n-gi>
          <n-gi style="background:red">
            <n-card title="Card"> Card Content </n-card>
          </n-gi>
          <n-gi style="background:green">
            <n-card title="Card"> Card Content </n-card>
          </n-gi>
        </n-grid>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "../auth";
import { defineComponent } from "vue";
import { NCard, NGrid, NGi } from "naive-ui";

export default defineComponent({
  mounted() {
    auth.getUser().then(() => {});
  },
  components: {
    NCard,
    NGrid,
    NGi,
  },
});
</script>

<style lang="scss">
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
</style>
